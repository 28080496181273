import { LoadingIndicator } from '@organice/atoms'
import { useAllUsersListQuery } from '@organice/graphql'
import { Alert, AutoComplete, AutoCompleteProps } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'

// TODO: Change to real type when it's available
// TODO: Handle slow loading & errors

// type UserValue = NonNullable<
//   ServiceByIdQuery['services_by_pk']
// >['serviceModules'][0]['attributeGroups'][0]['attributes'][0]['attributeValues'][0]['fullUser']

type UserValue = {
  id: bigint
  firstName?: string | null
  lastName?: string | null
  attributes?: Record<string, any>
  organisation?: {
    id: bigint
    name: string
  } | null
}

type UserAutocompleteProps = AutoCompleteProps & {
  organisationId?: bigint | number
  initialValue?: UserValue | null
}

type UserOptionType = {
  key: bigint
  value: string
  label: React.ReactNode
  user: UserValue
}

export const UserAutocomplete: React.FC<UserAutocompleteProps> = props => {
  const { organisationId, value } = props

  const { data, loading, error } = useAllUsersListQuery({
    variables: {
      where: {
        _and: [
          {
            organisationId: {
              _eq: organisationId,
            },
          },
          {
            isDisabled: {
              _neq: true,
            },
          },
        ],
      },
    },
  })

  const { t } = useTranslation()

  const [options, setOptions] = React.useState<UserOptionType[]>([])
  const [isTouched, setIsTouched] = React.useState(false)

  function createStringValueFromUser(user: UserValue | null | undefined) {
    if (!user) return ''
    return `${user.firstName || ''} ${user.lastName || ''}${user.attributes?.department ? ` (${user.attributes.department})` : ''}`
  }

  React.useEffect(() => {
    if (!data || isTouched) return
    setOptions(
      data.allUsers.map(u => ({
        label: (
          <>
            <span style={{ fontWeight: 'bold' }}>
              {u?.firstName} {u.lastName}{' '}
            </span>
            {u.attributes?.department ? (
              <span style={{ color: 'gray' }}>
                {' '}
                ({u.attributes.department})
              </span>
            ) : null}
          </>
        ),
        value: createStringValueFromUser(u) || '',
        key: u.id,
        user: u,
      })) || []
    )
  }, [data, isTouched])

  const handleSearch = (value: string) => {
    let res: UserOptionType[] = []
    if (!isTouched) setIsTouched(true)
    if (data) {
      res = data.allUsers
        ?.filter(u =>
          !value
            ? true
            : String(u.firstName)
                .toLowerCase()
                .startsWith(value.toLowerCase()) ||
              String(u.lastName).toLowerCase().startsWith(value.toLowerCase())
        )
        .map(u => ({
          label: (
            <>
              <span style={{ fontWeight: 'bold' }}>
                {u.firstName} {u.lastName}{' '}
              </span>
              {u.attributes?.department ? (
                <span style={{ color: 'gray' }}>
                  {' '}
                  ({u.attributes.department})
                </span>
              ) : null}
            </>
          ),
          value: createStringValueFromUser(u),
          key: u.id,
          user: u,
        }))
    }
    setOptions(res)
  }

  const initialUserStringValue = createStringValueFromUser(props.initialValue)
  const userStringValue =
    props.value && typeof props.value !== 'string'
      ? createStringValueFromUser(props.value)
      : undefined

  return (
    <AutoComplete
      allowClear
      defaultValue={initialUserStringValue}
      style={{ width: '100%' }}
      onSearch={handleSearch}
      options={options}
      notFoundContent={
        loading ? (
          <LoadingIndicator isLocal />
        ) : (
          <span>{t('service.attribute.user.notifications.noUserFound')}</span>
        )
      }
      {...props}
      value={userStringValue}
      onChange={(value, option) => {
        setIsTouched(true)
        props.onChange &&
          props.onChange(
            option ? (option as UserOptionType).user : null,
            option
          )
      }}
    />
  )
}
