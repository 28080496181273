import React from 'react'
import {
  RoomServices,
  GetRoomOccurrencesByEventIdSubscription,
  GetRoomServiceOccurrencesByEventIdSubscription,
  Rooms,
} from '@organice/graphql'
import { Dayjs } from 'dayjs'
import { RoomAttributeValueGroup } from '../../../types/room-service'
export type RoomMatrixid = string

export interface RoomMatrixDateRowConfig {
  resolution: number
  format: string
  unit: RoomMatrixZoomLevelUnit
}

export type MinutesPerUnitFunction = (date: Dayjs) => number

export interface NewRoomMatrixDateRowConfig {
  minResolution: number
  getFormattedDate: (date: Dayjs, maxChars?: number) => string
  getMinutesPerUnit: MinutesPerUnitFunction
  getPrev?: (date: Dayjs) => Dayjs
  getNext?: (date: Dayjs) => Dayjs
  getBeginForUnit: (date: Dayjs) => Dayjs
}

export enum RowType {
  RoomFolder = 'roomFolders',
  Rooms = 'rooms',
  EventRooms = 'eventRooms',
  RoomService = 'roomServices',
}

export enum RowTypePrefixes {
  RoomFolder = 'f',
  Rooms = 'r',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  EventRooms = 'r',
  RoomService = 's',
}

export enum BarTypePrefixes {
  RoomOccurrence = 'b.r',
  RoomServiceOccurrence = 'b.s',
}

export enum BarType {
  RoomOccurrence = 'roomOccurrences',
  RoomServiceOccurrence = 'roomServiceOccurrences',
}

export interface RoomMatrixBarRowData {
  readonly id: RoomMatrixid
  title: string
  parentId?: string
  type: RowType
}

export type MinimalBarData = {
  id?: number
  key: string
  name: string
  start: Dayjs
  end: Dayjs
  content?: React.ReactChild
}

export type PathElement = { key: string; name: string }

export interface RoomMatrixBarData {
  readonly id: RoomMatrixid
  key: RoomMatrixid
  parentId: string
  parentKey: string
  start: Dayjs
  end: Dayjs
  isDraft?: boolean
  fromRoomServiceOccurrenceId?: number
  fromRoomOccurrenceId?: number
  content?: React.ReactChild
  type: BarType
  barsInRow?: MinimalBarData[]
  guestCanEdit?: boolean
  roomService?: Pick<RoomServices, 'id' | 'name' | 'order' | 'roomId'> & {
    description?: string
  }
  path?: PathElement[]
  hasChanges?: boolean
  userData?: { data: PersonalUserData }[]
  roomAttributeValueGroups?: Pick<RoomAttributeValueGroup, 'id'>[]
  eventId?: number
}

export enum RoomMatrixZoomLevelUnit {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

export type RoomMatrixRowTreeElement = {
  id: number
  key: string
  title: string
  name: string
  type: RowType
  children?: RoomMatrixRowTreeElement[]
  shortDescription?: string
  description?: string
  roomServices?: MinimalRoomService[]
  occurences?: number
  order?: number | null
  parentKey?: string
  allowGuestEdit?: boolean
}

export type RoomMatrixRowElement = Omit<
  RoomMatrixRowTreeElement,
  'children'
> & {
  roomOccurrences?: RoomMatrixBarData[]
}

export interface RoomMatrixData {
  tree: RoomMatrixRowTreeElement[]
  rows: RoomMatrixRowElement[]
  bars: RoomMatrixBarData[]
  expandedKeys: string[]
  roomsTotal: number
}

export type RoomServiceListIstem = Pick<RoomServices, 'id' | 'name' | 'order'>

export type EventRoomServices = {
  [key: string]: RoomServiceListIstem[]
}

export type EventRoom = {
  __typename?: 'eventRooms' | undefined
} & {
  room: {
    __typename?: 'rooms' | undefined
  } & Pick<Rooms, 'id' | 'name'>
}

export type RoomOccurrence =
  GetRoomOccurrencesByEventIdSubscription['roomOccurrences'][0]

export type RoomServiceOccurence =
  GetRoomServiceOccurrencesByEventIdSubscription['roomServiceOccurrences'][0]

type MinimalRoomService = Pick<RoomServices, 'id' | 'name'> &
  Partial<Omit<RoomServices, 'id' | 'name'>>

export type RoomWithKey = EventRoom['room'] & {
  key: string
  roomServices?: MinimalRoomService[]
  eventId?: number | null
  fromRoomId?: number | null
}

export type MatrixFilterObject = {
  rooms: string
}
