import { Text } from '@organice/atoms'
import {
  useGetUserLazyQuery,
  useGetVisibleUserByIdLazyQuery,
} from '@organice/graphql'
import {
  UserOutlined,
  MailOutlined,
  MobileOutlined,
  PhoneOutlined,
} from '@ant-design/icons'
import IconBusiness from '@organice/icons/business.svg?react'
import IconUser from '@organice/icons/user_profile.svg?react'
import { Icon } from '@organice/atoms'
import React, { ComponentType } from 'react'
import styled from '@emotion/styled'
import { Button, Popover, Tooltip } from 'antd'
import { useMeContext } from '@organice/contexts'
import { useTranslation } from 'react-i18next'
import linesPattern from 'assets/svg/lines_pattern.svg'

type PersonProps = {
  user: {
    id: number
    firstName?: string | null
    lastName?: string | null
    fullName?: string | null
    organisation?: {
      name: string
    } | null
  }
  children?: React.ReactNode
  isPrint?: boolean
}

export const Person: React.FC<PersonProps> = ({
  user,
  children,
  isPrint = false,
}) => {
  const { id, firstName, lastName, fullName, organisation } = user
  const { t } = useTranslation()
  // const [fetchUser, { data: userData, loading, error }] = useGetUserLazyQuery()
  const [fetchVisibleUser, { data: visibleUserData }] =
    useGetVisibleUserByIdLazyQuery()
  const {
    state: { isAdmin },
  } = useMeContext()

  React.useEffect(() => {
    // fetchUser({
    //   variables: {
    //     id: id,
    //   },
    // })

    fetchVisibleUser({
      variables: {
        id: id,
      },
    })
  }, [])

  const visibleUser = visibleUserData?.allUsers?.[0]

  const name = fullName
    ? fullName
    : firstName
      ? `${firstName} ${lastName}`
      : `${visibleUser?.firstName} ${visibleUser?.lastName}`

  if (children) {
    if (!visibleUser) {
      return (
        <Tooltip title={t('user.userHasBeenDeleted')}>
          <DisabledInlineWrapper>{children}</DisabledInlineWrapper>
        </Tooltip>
      )
    }

    return (
      <>
        {user ? (
          <Popover
            title={
              <CardHeader>
                <InfoRow>
                  <InfoLabel>
                    <Icon component={IconUser} style={{ fontSize: 32 }} />
                  </InfoLabel>
                  <InfoValue>
                    <InfoSubRow className="strong large">{name}</InfoSubRow>
                    {visibleUser?.isDisabled ? (
                      <span
                        style={{
                          background: 'red',
                          color: 'white',
                          padding: '0 3px',
                        }}
                      >
                        {t('user.isDisabled')}
                      </span>
                    ) : null}
                  </InfoValue>
                </InfoRow>
              </CardHeader>
            }
            content={
              <>
                <ContactCard
                  contactInfo={{
                    isDisabled: Boolean(visibleUser?.isDisabled),
                    name: `${user?.firstName} ${user?.lastName}`,
                    organisation:
                      visibleUser?.organisation?.name || organisation?.name,
                    deplartment: visibleUser?.attributes?.department,
                    email: visibleUser?.publicEmail,
                    telephoneNumber: visibleUser?.attributes?.telephoneNumber,
                    mobile: visibleUser?.attributes?.mobile,
                  }}
                />
                {isAdmin ? (
                  <ButtonWrapper>
                    <Button
                      href={`/accounts/users/${user.id}`}
                      size="small"
                      type="primary"
                    >
                      {t('account.visitProfile')}
                    </Button>
                  </ButtonWrapper>
                ) : null}
              </>
            }
            trigger="click"
          >
            {visibleUser?.isDisabled ? (
              <DisabledInlineWrapper>{children}</DisabledInlineWrapper>
            ) : (
              <>{children}</>
            )}
          </Popover>
        ) : (
          children
        )}
      </>
    )
  }

  if (isPrint) {
    return (
      <div>
        <InfoRow>
          <InfoValue>
            <InfoSubRow className="strong large">{name}</InfoSubRow>
            {visibleUser?.isDisabled ? (
              <span
                style={{
                  background: 'red',
                  color: 'white',
                  padding: '0 3px',
                }}
              >
                {t('user.isDisabled')}
              </span>
            ) : null}
          </InfoValue>
        </InfoRow>
        <ContactCard
          contactInfo={{
            isDisabled: Boolean(visibleUser?.isDisabled),
            name: `${user?.firstName} ${user?.lastName}`,
            organisation: visibleUser?.organisation?.name || organisation?.name,
            deplartment: visibleUser?.attributes?.department,
            email: visibleUser?.publicEmail,
            telephoneNumber: visibleUser?.attributes?.telephoneNumber,
            mobile: visibleUser?.attributes?.mobile,
          }}
        />
      </div>
    )
  }

  return (
    <PersonWrapper isDisabled={Boolean(visibleUser?.isDisabled)}>
      {user ? (
        <Popover
          title={
            <CardHeader>
              <InfoRow>
                <InfoLabel>
                  <Icon component={IconUser} style={{ fontSize: 32 }} />
                </InfoLabel>
                <InfoValue>
                  <InfoSubRow className="strong large">{`${user?.firstName} ${user?.lastName}`}</InfoSubRow>
                  {visibleUser?.isDisabled ? (
                    <span
                      style={{
                        background: 'red',
                        color: 'white',
                        padding: '0 3px',
                      }}
                    >
                      {t('user.isDisabled')}
                    </span>
                  ) : null}
                </InfoValue>
              </InfoRow>
            </CardHeader>
          }
          content={
            <>
              <ContactCard
                contactInfo={{
                  isDisabled: Boolean(visibleUser?.isDisabled),
                  name: `${user?.firstName} ${user?.lastName}`,
                  organisation:
                    visibleUser?.organisation?.name || organisation?.name,
                  deplartment: visibleUser?.attributes?.department,
                  email: visibleUser?.publicEmail,
                  telephoneNumber: visibleUser?.attributes?.telephoneNumber,
                  mobile: visibleUser?.attributes?.mobile,
                }}
              />
              {isAdmin ? (
                <ButtonWrapper>
                  <Button
                    href={`/accounts/users/${user.id}`}
                    size="small"
                    type="primary"
                  >
                    {t('account.visitProfile')}
                  </Button>
                </ButtonWrapper>
              ) : null}
            </>
          }
          trigger="click"
        >
          <StyledUserIcon />
        </Popover>
      ) : null}
      <Text
        style={{ paddingLeft: 0 }}
      >{`${user?.firstName} ${user?.lastName}`}</Text>
    </PersonWrapper>
  )
}

type ContactCardProps = {
  contactInfo: {
    name: string
    isDisabled?: boolean
    organisation?: string | null
    deplartment?: string | null
    email?: string | null
    telephoneNumber?: string | null
    mobile?: string | null
  }
}

const ContactCard: React.FC<ContactCardProps> = ({ contactInfo }) => {
  const {
    name,
    isDisabled,
    organisation,
    deplartment,
    email,
    telephoneNumber,
    mobile,
  } = contactInfo
  return (
    <div>
      <InfoBlock isDisabled={isDisabled}>
        {organisation ? (
          <InfoRow>
            <InfoLabel>
              <Icon component={IconBusiness} />
            </InfoLabel>
            <InfoValue>
              <InfoSubRow className="strong">{organisation}</InfoSubRow>
              <InfoSubRow className="light small">{deplartment}</InfoSubRow>
            </InfoValue>
          </InfoRow>
        ) : null}

        {email ? (
          <InfoRow>
            <InfoLabel>
              <MailOutlined />
            </InfoLabel>
            <InfoValue>
              <a href={`mailto:${email}`}>{email}</a>
            </InfoValue>
          </InfoRow>
        ) : null}

        {telephoneNumber ? (
          <InfoRow>
            <InfoLabel>
              <PhoneOutlined />
            </InfoLabel>
            <InfoValue>
              <a href={`tel:${telephoneNumber}`}>{telephoneNumber}</a>
            </InfoValue>
          </InfoRow>
        ) : null}

        {mobile ? (
          <InfoRow>
            <InfoLabel>
              <MobileOutlined />
            </InfoLabel>
            <InfoValue>
              <a href={`tel:${mobile}`}>{mobile}</a>
            </InfoValue>
          </InfoRow>
        ) : null}
      </InfoBlock>
      {isDisabled ? <DisabledOverlay></DisabledOverlay> : null}
    </div>
  )
}

const ButtonWrapper = styled.div(({ theme }) => ({
  paddingTop: 20,
  paddingBottom: 10,
  //   display: 'flex',
  //   justifyContent: 'center',
}))

const CardHeader = styled.div(({ theme }) => ({
  //   borderBottom: `1px solid ${theme.grey3}`,
}))

const InfoBlock = styled.div<{ isDisabled?: boolean }>(({ isDisabled }) => ({
  display: 'flex',
  flexDirection: 'column' as const,
  gap: 12,
  // make diagonal crossed lines overlay if isDisabled
  textDecoration: isDisabled ? 'line-through' : 'none',
  opacity: isDisabled ? 0.5 : 1,
}))

const InfoRow = styled.div(() => ({
  display: 'flex',
  gap: 5,
  alignItems: 'center',
}))

const InfoLabel = styled.div(() => ({
  marginRight: 5,
  alignSelf: 'flex-start',
}))

const InfoValue = styled.div(() => ({}))

const InfoSubRow = styled.div(({ theme }) => ({
  '&.large': {
    fontSize: theme.heading4Size,
  },
  '&.small': {
    fontSize: theme.fontSizeSm,
  },
  '&.strong': {
    fontWeight: 'bold',
  },
  '&.light': {
    color: theme.textColorLight,
  },
}))

const PersonWrapper = styled.div<{ isDisabled?: boolean }>(
  ({ isDisabled }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    // add style for isDisabled that is crossed out
    textDecoration: isDisabled ? 'line-through' : 'none',
  })
)

const DisabledOverlay = styled.div(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  // backgroundImage: `url("${linesPattern}")`,
  opacity: 0.5,
}))

const DisabledInlineWrapper = styled.span(({ theme }) => ({
  textDecoration: 'line-through',
}))

const StyledUserIcon = styled(UserOutlined as ComponentType)(({ theme }) => ({
  cursor: 'pointer',
  //   background: theme.grey4,
  border: `1px solid ${theme.grey4}`,
  borderRadius: '50%',
  width: 18,
  height: 18,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  svg: {
    path: {
      fill: theme.grey4,
    },
  },
}))
