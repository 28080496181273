import React, { RefAttributes } from 'react'
import { Form, notification, Alert } from 'antd'
import {
  UploadOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import CalAdd from '@organice/icons/cal-add.svg?react'
import {
  AttributeValueProps,
  isAttributeValue,
  TextAttributeValue,
  NumberAttributeValue,
  BooleanAttributeValue,
  DateAttributeValue,
  RepeatableDateAttributeValue,
  FileAttributeValue,
  DateType,
  AttributeType,
  SelectAttributeValue,
  UserAttributeValue,
} from '../../../types/service'
import { AttributeValueFiles, AttributeFiles } from '@organice/graphql'
import {
  Input,
  Checkbox,
  Button,
  Icon,
  InputNumber,
  DatePickerAutoConfirm,
  RangePickerAutoConfirm,
  Select,
  DatepickerAutoconfirmProps,
  DatePicker,
} from '@organice/atoms'
import { useTranslation } from 'react-i18next'
import styled from '@emotion/styled'
import { ErrorBoundary } from '@organice/utils/errorBoundary'
import { FileUpload } from '@organice/molecules/fileUpload'
import { CustomTimeInput } from '@organice/molecules/custom-time-input'
import { useEventContext } from '@organice/contexts/event/event-context'
import { TimeUtil } from '@organice/utils/date'
import { validationRules } from '@organice/utils/form'
import { dateConfig } from '@organice/config'
import {
  EventDateSuggestions,
  DateSuggestionOptions,
  RepeatableDateSuggestionOptions,
} from '@organice/molecules/eventDateSuggestions'
import { useFormContext } from '@organice/contexts'
import {
  SEPERATOR,
  NEW_GROUP_INDICATOR,
} from '@organice/templates/eventService/useUpload'
import { FormInstance } from 'antd/es/form/Form'
import { UserAutocomplete } from '@organice/molecules/user-autocomplete'

const dayjs = TimeUtil.getDayjs()

const INPUT_NUMBER_MIN_VALUE = 0

const { validateDateTimeRange, validateDateRangeTime } = validationRules

export const EventServiceAttributeInput: React.FC<
  AttributeValueProps
> = attributeValue => {
  return (
    <Form.Item noStyle>
      <ErrorBoundary>
        <AttributeUserInput {...attributeValue}></AttributeUserInput>
      </ErrorBoundary>
    </Form.Item>
  )
}

const AttributeUserInput: React.FC<AttributeValueProps> = props => {
  // below, you'll find a type gaurd per attribute type and an according return statement
  // if none of the type gaurds pass, an error will be thrown

  const { t } = useTranslation()
  if (isAttributeValue<TextAttributeValue>(props, AttributeType.Text)) {
    return (
      <Form.Item noStyle>
        <TextAttributeUserInput {...props} />
      </Form.Item>
    )
  }

  if (isAttributeValue<NumberAttributeValue>(props, AttributeType.Number)) {
    return (
      <Form.Item noStyle>
        <NumberAttributeUserInput {...props}></NumberAttributeUserInput>
      </Form.Item>
    )
  }

  if (isAttributeValue<BooleanAttributeValue>(props, AttributeType.Boolean)) {
    return (
      <Form.Item noStyle>
        <BooleanAttributeUserInput {...props}></BooleanAttributeUserInput>
      </Form.Item>
    )
  }

  if (isAttributeValue<DateAttributeValue>(props, AttributeType.Date)) {
    return (
      <Form.Item noStyle>
        <DateAttributeUserInput {...props} />
      </Form.Item>
    )
  }

  if (isAttributeValue<SelectAttributeValue>(props, AttributeType.Select)) {
    return (
      <Form.Item noStyle>
        <SelectAttributeUserInput {...props} />
      </Form.Item>
    )
  }

  if (isAttributeValue<FileAttributeValue>(props, AttributeType.File)) {
    return (
      <FileUpload
        {...props}
        disableUpload={!props.attribute.config.allowUpload}
        attribute={props.attribute.id}
        attributeValueId={props.id}
        attributeFiles={props.attribute.attributeFiles as AttributeFiles[]}
        attributeGroupId={props.attributeValueGroupId}
        fileList={props.attributeValueFiles as AttributeValueFiles[]}
      >
        <Button icon={<UploadOutlined />}>
          {t('service.attribute.upload.cta')}
        </Button>
      </FileUpload>
    )
  }

  if (isAttributeValue<UserAttributeValue>(props, AttributeType.User)) {
    return (
      <Form.Item noStyle>
        <UserAttributeUserInput {...props} />
      </Form.Item>
    )
  }

  throw new Error(
    `No input defined for ${
      props.attribute.attributeType
    }, got this for props: ${JSON.stringify(props, undefined, 2)}`
  )

  return null
}

const TextAttributeUserInput: React.FC<TextAttributeValue> = attributeValue => {
  return (
    <Form.Item
      name={attributeValue.id}
      initialValue={
        attributeValue.value.value ? attributeValue.value.value : ''
      }
      noStyle
    >
      <Input.TextArea autoSize={{ maxRows: 10 }}></Input.TextArea>
    </Form.Item>
  )
}

const UserAttributeUserInput: React.FC<UserAttributeValue> = attributeValue => {
  const { t } = useTranslation()
  const organisation = attributeValue.attribute.organisation

  return (
    <Form.Item
      name={attributeValue.id}
      initialValue={attributeValue.user ? attributeValue.user : ''}
      noStyle
    >
      {organisation ? (
        <UserAutocomplete
          organisationId={organisation?.id}
          initialValue={attributeValue.user}
        />
      ) : (
        <Alert
          message={t('messages.serviceModules.userFieldWithoutOrganisationId')}
          type="error"
        />
      )}
    </Form.Item>
  )
}

const NumberAttributeUserInput: React.FC<
  NumberAttributeValue
> = attributeValue => {
  const {
    attribute: {
      config: { allowDecimals = false, max },
    },
  } = attributeValue

  const { t } = useTranslation()

  return (
    <Form.Item
      name={attributeValue.id}
      initialValue={
        attributeValue.value?.value !== undefined
          ? attributeValue.value.value
          : undefined
      }
      noStyle
    >
      <InputNumber
        max={
          attributeValue.attribute.config && attributeValue.attribute.config.max
        }
        min={INPUT_NUMBER_MIN_VALUE}
        onBlur={e => {
          const numberValue = Number(
            e.currentTarget.value.replace(/\./, '').replace(/,/g, '.')
          )

          if (isNaN(numberValue)) {
            notification.info({
              message: t('service.attribute.number.notifications.invalid'),
            })
          }

          if (numberValue < INPUT_NUMBER_MIN_VALUE) {
            notification.info({
              message: t('service.attribute.number.notifications.lessThanMin'),
            })
          }

          if (max && numberValue > max) {
            notification.info({
              message: t(
                'service.attribute.number.notifications.greaterThanMax'
              ),
            })
          }
        }}
        stringMode
        /* Parse german commata seperator */
        formatter={value => {
          if (!value) return ''

          return allowDecimals
            ? parseFloat(`${value}`.replace(/,/, '.')).toLocaleString('de-DE', {
                maximumFractionDigits: 9,
              })
            : `${parseInt(`${value}`)}`
        }}
        parser={x =>
          parseFloat(
            `${x}`.replace(/,/, '#').replace(/\./g, '').replace(/#/, '.')
          )
        }
        onKeyPress={e => {
          if (!/^[0-9,]$/.test(e.key)) {
            e.preventDefault()
          }
        }}
      />
    </Form.Item>
  )
}

const BooleanAttributeUserInput: React.FC<
  BooleanAttributeValue
> = attributeValue => {
  const { t } = useTranslation()

  return (
    <Form.Item
      name={attributeValue.id}
      initialValue={
        attributeValue.value?.value !== undefined
          ? attributeValue.value.value
          : false
      }
      valuePropName="checked"
      noStyle
    >
      <Checkbox>{t('service.attribute.checkbox.active')}</Checkbox>
    </Form.Item>
  )
}

const SelectAttributeUserInput: React.FC<
  SelectAttributeValue
> = attributeValue => {
  const { t } = useTranslation()
  const config = attributeValue.attribute.config
  const options = config.options.map((o, i) => {
    if (typeof o === 'string' || typeof o === 'number') {
      return (
        <Select.Option key={`o-${i}`} value={o}>
          {o}
        </Select.Option>
      )
    }
  })

  return (
    <Form.Item
      name={attributeValue.id}
      initialValue={
        attributeValue.value?.value !== undefined
          ? attributeValue.value.value
          : []
      }
      noStyle
    >
      <Select
        allowClear
        mode={config.type || undefined}
        // placeholder="Please select"
        // defaultValue={[]}
        style={{ width: '100%' }}
      >
        {options}
      </Select>
    </Form.Item>
  )
}

const DateAttributeUserInput: React.FC<DateAttributeValue> = attributeValue => {
  const { t } = useTranslation()
  const { event } = useEventContext()
  const { form, updaterFunc, showDateSuggestions } = useFormContext()

  const dateSuggestionSelectHandler = (
    attributeValue: DateAttributeValue,
    selectedDate: DateSuggestionOptions
  ) => {
    const { formKey, value } = prepareAttributeValueUpdate(
      form,
      attributeValue,
      selectedDate
    )
    form.setFieldsValue({
      [formKey]: value,
    })
    updaterFunc({ [formKey]: value }, form.getFieldsValue())
  }

  const eventStartDate = event?.startDate
  const eventEndDate = event?.endDate

  if (attributeValue.attribute.config.repeatable) {
    return (
      <RepeatableDateUserInput
        {...(attributeValue as RepeatableDateAttributeValue)}
      ></RepeatableDateUserInput>
    )
  }

  const value = attributeValue.value?.value

  const getValue = (index: number) =>
    Array.isArray(value) && typeof value[index] === 'string'
      ? dayjs(value[index] as string)
      : undefined

  switch (attributeValue.attribute.config.dateType) {
    case DateType.DATE:
      return (
        <InputWithSuggestionsWrapper>
          {showDateSuggestions && (
            <SuggestionIcon>
              <EventDateSuggestions
                fieldId={attributeValue.id}
                dateType={attributeValue.attribute.config.dateType}
                onSelect={selectedDate => {
                  dateSuggestionSelectHandler(attributeValue, selectedDate)
                }}
              />
            </SuggestionIcon>
          )}
          <FormInput>
            <Form.Item
              name={attributeValue.id}
              initialValue={getValue(0)}
              noStyle
            >
              <DatePickerAutoConfirm
                // format={dateConfig.DATE_FORMAT}
                inputFormat={dateConfig.INPUT_DATE_FORMAT}
                displayFormat={dateConfig.DATE_FORMAT}
                defaultPickerValue={value ? undefined : dayjs(eventStartDate)}
                parentRange={
                  eventStartDate &&
                  eventEndDate && [dayjs(eventStartDate), dayjs(eventEndDate)]
                }
              />
            </Form.Item>
          </FormInput>
        </InputWithSuggestionsWrapper>
      )

    case DateType.DATE_RANGE:
      return (
        <InputWithSuggestionsWrapper>
          {showDateSuggestions && (
            <SuggestionIcon>
              <EventDateSuggestions
                fieldId={attributeValue.id}
                dateType={attributeValue.attribute.config.dateType}
                onSelect={selectedDate => {
                  dateSuggestionSelectHandler(attributeValue, selectedDate)
                }}
              />
            </SuggestionIcon>
          )}
          <FormInput>
            <Form.Item
              initialValue={[getValue(0), getValue(1)]}
              name={attributeValue.id}
              noStyle
            >
              <RangePickerAutoConfirm
                // format={dateConfig.DATE_FORMAT}
                inputFormat={dateConfig.INPUT_DATE_FORMAT}
                displayFormat={dateConfig.DATE_FORMAT}
                parentRange={
                  eventStartDate &&
                  eventEndDate && [dayjs(eventStartDate), dayjs(eventEndDate)]
                }
              />
            </Form.Item>
          </FormInput>
        </InputWithSuggestionsWrapper>
      )

    case DateType.DATE_RANGE_TIME:
      return (
        <InputWithSuggestionsWrapper>
          {showDateSuggestions && (
            <SuggestionIcon>
              <EventDateSuggestions
                fieldId={attributeValue.id}
                dateType={attributeValue.attribute.config.dateType}
                onSelect={selectedDate => {
                  dateSuggestionSelectHandler(attributeValue, selectedDate)
                }}
              />
            </SuggestionIcon>
          )}
          <FormInput>
            <Form.Item
              initialValue={[getValue(0), getValue(1)]}
              name={attributeValue.id}
              // noStyle
              rules={[
                {
                  validator: validateDateRangeTime,
                  validateTrigger: ['onBlur'],
                },
              ]}
            >
              <RangePickerAutoConfirm
                // format={`${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                inputFormat={`${dateConfig.INPUT_DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                displayFormat={`${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                showTime
                parentRange={
                  eventStartDate &&
                  eventEndDate && [dayjs(eventStartDate), dayjs(eventEndDate)]
                }
              />
            </Form.Item>
          </FormInput>
        </InputWithSuggestionsWrapper>
      )

    case DateType.DATE_TIME:
      return (
        <InputWithSuggestionsWrapper>
          {showDateSuggestions && (
            <SuggestionIcon>
              <EventDateSuggestions
                fieldId={attributeValue.id}
                dateType={attributeValue.attribute.config.dateType}
                onSelect={selectedDate => {
                  dateSuggestionSelectHandler(attributeValue, selectedDate)
                }}
              />
            </SuggestionIcon>
          )}
          <FormInput>
            <Form.Item
              initialValue={getValue(0)}
              name={attributeValue.id}
              noStyle
            >
              <DatePickerAutoConfirm
                autoConfirm
                // format={`${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                inputFormat={`${dateConfig.INPUT_DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                displayFormat={`${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                showTime
                defaultPickerValue={dayjs(eventStartDate)}
                parentRange={
                  eventStartDate &&
                  eventEndDate && [dayjs(eventStartDate), dayjs(eventEndDate)]
                }
              />
            </Form.Item>
          </FormInput>
        </InputWithSuggestionsWrapper>
      )

    case DateType.DATE_TIME_RANGE:
      // eslint-disable-next-line no-case-declarations
      const name = (idx: number) =>
        Array.isArray(attributeValue.id)
          ? [...attributeValue.id, idx]
          : [attributeValue.id, idx]

      return (
        <InputWithSuggestionsWrapper>
          {showDateSuggestions && (
            <SuggestionIcon>
              <EventDateSuggestions
                fieldId={attributeValue.id}
                dateType={attributeValue.attribute.config.dateType}
                onSelect={selectedDate => {
                  dateSuggestionSelectHandler(attributeValue, selectedDate)
                }}
              />
            </SuggestionIcon>
          )}
          <FormInput>
            <Form.Item
              name={attributeValue.id}
              rules={[
                {
                  validator: validateDateTimeRange,
                },
              ]}
            >
              <Form.Item noStyle>
                <DateTimeRangeContainer>
                  <Form.Item name={name(0)} initialValue={getValue(0)} noStyle>
                    <DatePickerAutoConfirm
                      // format={dateConfig.DATE_FORMAT}
                      inputFormat={dateConfig.INPUT_DATE_FORMAT}
                      displayFormat={dateConfig.DATE_FORMAT}
                      defaultPickerValue={
                        value ? undefined : dayjs(eventStartDate)
                      }
                      parentRange={
                        eventStartDate &&
                        eventEndDate && [
                          dayjs(eventStartDate),
                          dayjs(eventEndDate),
                        ]
                      }
                    />
                  </Form.Item>

                  <Form.Item name={name(1)} initialValue={getValue(0)} noStyle>
                    {/* <DatePicker.TimePicker
                  placeholder={t('service.attribute.date.start')}
                  format={'HH:mm'}
                  defaultPickerValue={value ? undefined : dayjs(eventStartDate)}
                /> */}
                    <CustomTimeInput
                      placeholder={t('service.attribute.date.start')}
                    />
                  </Form.Item>
                  <Form.Item name={name(2)} initialValue={getValue(1)} noStyle>
                    <CustomTimeInput
                      placeholder={t('service.attribute.date.end')}
                    />
                    {/* <DatePicker.TimePicker
                  placeholder={t('service.attribute.date.end')}
                  format={'HH:mm'}
                  defaultPickerValue={value ? undefined : dayjs(eventStartDate)}
                /> */}
                  </Form.Item>
                </DateTimeRangeContainer>
              </Form.Item>
            </Form.Item>
          </FormInput>
        </InputWithSuggestionsWrapper>
      )

    default:
      console.warn(
        'It is not clear what Field to render (Date, DateRange, DateRangeTime etc.). This means you probably forgot to set the date property on your attribute object.',
        attributeValue
      )
      return (
        <Alert
          message={
            <>
              <ExclamationCircleOutlined />{' '}
              {t('configService.errors.fieldConfigurationError')}
            </>
          }
          type="error"
        />
      )
  }
}

export const RepeatableDateUserInput: React.FC<
  RepeatableDateAttributeValue
> = attributeValue => {
  const { t } = useTranslation()
  const { event } = useEventContext()
  const { form, updaterFunc, showDateSuggestions } = useFormContext()

  const dateSuggestionSelectHandler = (
    attributeValue: DateAttributeValue,
    selectedDate: DateSuggestionOptions
  ) => {
    const { formKey, value } = prepareAttributeValueUpdate(
      form,
      attributeValue,
      selectedDate
    )
    form.setFieldsValue({
      [formKey]: value,
    })
    updaterFunc({ [formKey]: value }, form.getFieldsValue())
  }

  const eventStartDate = event?.startDate
  const eventEndDate = event?.endDate

  // this are the initial values as they come from the api, as an 2D array of either string or undefined
  const initialValuesRaw =
    // the value needs to be an array and every entry in that array needs to be an array as well
    // also it needs to be at least on element in the outer array, because an empty array
    // would lead to nothin being rendered
    Array.isArray(attributeValue.value?.value) &&
    attributeValue.value.value.length > 0 &&
    attributeValue.value.value.every(a => Array.isArray(a))
      ? attributeValue.value.value
      : [[]] // this may happen for newly created groups

  // this results in a 2D array of either undefined, if the raw value was undefiend, or a dayjs instance
  // if the raw value was a string
  // use this for DatePicker.RangePicker or similar, where the expected value is a a Dayjs[]
  const initialValues2D = initialValuesRaw.map(stringArray =>
    stringArray.map(dateString =>
      typeof dateString === 'string' ? dayjs(dateString) : undefined
    )
  )

  const initialValuesDateTimeRange = initialValues2D.map(
    entry => [entry[0], entry[0], entry[1]] //as const
  )

  // this flattens all the values in the 2D array to a 1D array
  // use this for Date inputs that expect a Dayjs, not a Dayjs[]
  const initialValuesDayjsFlatRaw = initialValues2D.reduce((acc, curr) => {
    return [...acc, ...curr]
  }, [])

  // when a new group is created, it needs to be at least one element (even if it's undefined) in the array
  const initialValuesFlat =
    initialValuesDayjsFlatRaw.length > 0
      ? initialValuesDayjsFlatRaw
      : [undefined]

  switch (attributeValue.attribute.config.dateType) {
    case DateType.DATE:
      return (
        <DateListContainer>
          <Form.List
            name={attributeValue.id}
            initialValue={
              initialValuesFlat.length > 0 ? initialValuesFlat : [undefined]
            }
          >
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(field => {
                    return (
                      <Form.Item noStyle key={field.key}>
                        <DateListEntryContainer>
                          <DateListEntryButtonContainer>
                            <Button
                              // size={'small'}
                              icon={<DeleteOutlined />}
                              onClick={() => remove(field.name)}
                              // disabled={fields.length === 1}
                            ></Button>
                          </DateListEntryButtonContainer>
                          <Form.Item {...field} noStyle>
                            <DatePickerAutoConfirm
                              // format={dateConfig.DATE_FORMAT}
                              inputFormat={dateConfig.INPUT_DATE_FORMAT}
                              displayFormat={dateConfig.DATE_FORMAT}
                              defaultPickerValue={dayjs(eventStartDate)}
                              parentRange={
                                eventStartDate &&
                                eventEndDate && [
                                  dayjs(eventStartDate),
                                  dayjs(eventEndDate),
                                ]
                              }
                            />
                          </Form.Item>
                        </DateListEntryContainer>
                      </Form.Item>
                    )
                  })}

                  <AddDateContainer>
                    {showDateSuggestions && (
                      <EventDateSuggestions
                        isRepeatable={true}
                        dateType={attributeValue.attribute.config.dateType}
                        onSelect={selectedDate => {
                          dateSuggestionSelectHandler(
                            attributeValue,
                            selectedDate
                          )
                        }}
                      />
                    )}

                    <Button
                      onClick={() => add()}
                      icon={<Icon component={CalAdd} />}
                    >
                      {t('service.attribute.date.add')}
                    </Button>
                  </AddDateContainer>
                </>
              )
            }}
          </Form.List>
        </DateListContainer>
      )
    case DateType.DATE_TIME:
      return (
        <DateListContainer>
          <Form.List name={attributeValue.id} initialValue={initialValuesFlat}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(field => {
                    return (
                      <Form.Item noStyle key={field.key}>
                        <DateListEntryContainer>
                          <Form.Item noStyle>
                            <DateListEntryButtonContainer>
                              <Button
                                // size={'small'}
                                icon={<DeleteOutlined />}
                                onClick={() => remove(field.name)}
                                // disabled={fields.length === 1}
                              ></Button>
                            </DateListEntryButtonContainer>
                            <Form.Item {...field} noStyle>
                              <DatePickerAutoConfirm
                                autoConfirm
                                inputFormat={`${dateConfig.INPUT_DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                                displayFormat={`${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                                showTime
                                defaultPickerValue={dayjs(eventStartDate)}
                                parentRange={
                                  eventStartDate &&
                                  eventEndDate && [
                                    dayjs(eventStartDate),
                                    dayjs(eventEndDate),
                                  ]
                                }
                              />
                            </Form.Item>
                          </Form.Item>
                        </DateListEntryContainer>
                      </Form.Item>
                    )
                  })}

                  <AddDateContainer>
                    {showDateSuggestions && (
                      <EventDateSuggestions
                        isRepeatable={true}
                        dateType={attributeValue.attribute.config.dateType}
                        onSelect={selectedDate => {
                          dateSuggestionSelectHandler(
                            attributeValue,
                            selectedDate
                          )
                        }}
                      />
                    )}

                    <Button
                      onClick={() => add()}
                      icon={<Icon component={CalAdd} />}
                    >
                      {t('service.attribute.date.add')}
                    </Button>
                  </AddDateContainer>
                </>
              )
            }}
          </Form.List>
        </DateListContainer>
      )
    case DateType.DATE_RANGE:
      return (
        <DateListContainer>
          <Form.List name={attributeValue.id} initialValue={initialValues2D}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(field => {
                    return (
                      <>
                        <Form.Item key={field.key} noStyle>
                          <DateListEntryContainer>
                            <Form.Item noStyle>
                              <DateListEntryButtonContainer>
                                <Button
                                  // size={'small'}
                                  icon={<DeleteOutlined />}
                                  onClick={() => remove(field.name)}
                                  // disabled={fields.length === 1}
                                ></Button>
                              </DateListEntryButtonContainer>
                              <Form.Item {...field} noStyle>
                                <RangePickerAutoConfirm
                                  // format={dateConfig.DATE_FORMAT}
                                  inputFormat={dateConfig.INPUT_DATE_FORMAT}
                                  displayFormat={dateConfig.DATE_FORMAT}
                                  parentRange={
                                    eventStartDate &&
                                    eventEndDate && [
                                      dayjs(eventStartDate),
                                      dayjs(eventEndDate),
                                    ]
                                  }
                                />
                              </Form.Item>
                            </Form.Item>
                          </DateListEntryContainer>
                        </Form.Item>
                      </>
                    )
                  })}
                  <AddDateContainer>
                    {showDateSuggestions && (
                      <EventDateSuggestions
                        isRepeatable={true}
                        dateType={attributeValue.attribute.config.dateType}
                        onSelect={selectedDate => {
                          dateSuggestionSelectHandler(
                            attributeValue,
                            selectedDate
                          )
                        }}
                      />
                    )}
                    <Button
                      onClick={() => add()}
                      icon={<Icon component={CalAdd} />}
                    >
                      {t('service.attribute.date.add')}
                    </Button>
                  </AddDateContainer>
                </>
              )
            }}
          </Form.List>
        </DateListContainer>
      )
    case DateType.DATE_RANGE_TIME:
      return (
        <DateListContainer>
          <Form.List name={attributeValue.id} initialValue={initialValues2D}>
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(field => {
                    return (
                      <Form.Item key={field.key} noStyle>
                        <DateListEntryContainer>
                          <Form.Item // noStyle
                            name={field.name}
                            rules={[
                              {
                                validator: validateDateRangeTime,
                              },
                            ]}
                          >
                            <DateListEntryButtonContainer>
                              <Button
                                // size={'small'}
                                icon={<DeleteOutlined />}
                                onClick={() => remove(field.name)}
                                // disabled={fields.length === 1}
                              ></Button>
                            </DateListEntryButtonContainer>
                            <Form.Item {...field} noStyle>
                              <RangePickerAutoConfirm
                                // format={`${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                                inputFormat={`${dateConfig.INPUT_DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                                displayFormat={`${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`}
                                showTime={{
                                  format: `${dateConfig.DATE_FORMAT} ${dateConfig.TIME_FORMAT}`,
                                }}
                                parentRange={
                                  eventStartDate &&
                                  eventEndDate && [
                                    dayjs(eventStartDate),
                                    dayjs(eventEndDate),
                                  ]
                                }
                              />
                            </Form.Item>
                          </Form.Item>
                        </DateListEntryContainer>
                      </Form.Item>
                    )
                  })}
                  <AddDateContainer>
                    {showDateSuggestions && (
                      <EventDateSuggestions
                        isRepeatable={true}
                        dateType={attributeValue.attribute.config.dateType}
                        onSelect={selectedDate => {
                          dateSuggestionSelectHandler(
                            attributeValue,
                            selectedDate
                          )
                        }}
                      />
                    )}
                    <Button
                      onClick={() => add()}
                      icon={<Icon component={CalAdd} />}
                    >
                      {t('service.attribute.date.add')}
                    </Button>
                  </AddDateContainer>
                </>
              )
            }}
          </Form.List>
        </DateListContainer>
      )
    case DateType.DATE_TIME_RANGE:
      return (
        <DateListContainer>
          <Form.List
            name={attributeValue.id}
            initialValue={initialValuesDateTimeRange}
          >
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(field => {
                    return (
                      <DateListEntryContainer key={field.key}>
                        <Form.Item
                          // noStyle
                          name={field.name}
                          rules={[
                            {
                              validator: validateDateTimeRange,
                            },
                          ]}
                        >
                          <DateListEntryButtonContainer>
                            <Button
                              // size={'small'}
                              icon={<DeleteOutlined />}
                              onClick={() => remove(field.name)}
                              // disabled={fields.length === 1}
                            ></Button>
                          </DateListEntryButtonContainer>
                          <Form.Item noStyle>
                            <DateTimeRangeContainer>
                              <Form.Item name={[field.name, 0]} noStyle>
                                <StyledDatePickerAutoConfirm
                                  // format={dateConfig.DATE_FORMAT}
                                  inputFormat={dateConfig.INPUT_DATE_FORMAT}
                                  displayFormat={dateConfig.DATE_FORMAT}
                                  defaultPickerValue={dayjs(eventStartDate)}
                                  parentRange={
                                    eventStartDate &&
                                    eventEndDate && [
                                      dayjs(eventStartDate),
                                      dayjs(eventEndDate),
                                    ]
                                  }
                                />
                              </Form.Item>

                              <Form.Item name={[field.name, 1]} noStyle>
                                <CustomTimeInput
                                  placeholder={t(
                                    'service.attribute.date.start'
                                  )}
                                />

                                {/* <DatePicker.TimePicker
                                  placeholder={t(
                                    'service.attribute.date.start'
                                  )}
                                  format={'HH:mm'}
                                /> */}
                              </Form.Item>

                              <Form.Item name={[field.name, 2]} noStyle>
                                <CustomTimeInput
                                  placeholder={t('service.attribute.date.end')}
                                />
                                {/* <DatePicker.TimePicker
                                    placeholder={t(
                                      'service.attribute.date.end'
                                    )}
                                    format={'HH:mm'}
                                  /> */}
                              </Form.Item>
                            </DateTimeRangeContainer>
                          </Form.Item>
                        </Form.Item>
                      </DateListEntryContainer>
                    )
                  })}

                  <AddDateContainer>
                    {showDateSuggestions && (
                      <EventDateSuggestions
                        isRepeatable={true}
                        dateType={attributeValue.attribute.config.dateType}
                        onSelect={selectedDate => {
                          dateSuggestionSelectHandler(
                            attributeValue,
                            selectedDate
                          )
                        }}
                      />
                    )}
                    <Button
                      onClick={() => add()}
                      icon={<Icon component={CalAdd} />}
                    >
                      {t('service.attribute.date.add')}
                    </Button>
                  </AddDateContainer>
                </>
              )
            }}
          </Form.List>
        </DateListContainer>
      )
  }
}

/* Utility for Date Autocomplete > Handles form keys and values (especially for repeatable groups) */

const prepareAttributeValueUpdate = (
  form: FormInstance<any>,
  attributeValue: DateAttributeValue,
  selectedDate: DateSuggestionOptions
) => {
  const isInNewAttrValGroup = Array.isArray(attributeValue.id)

  const [index, attrId] = isInNewAttrValGroup ? attributeValue.id : []
  const attrValGroupId = attributeValue.attributeValueGroupId

  const newAttrValGroupKey = `${NEW_GROUP_INDICATOR}${SEPERATOR}groupId${SEPERATOR}${attrValGroupId}`
  const valGroupArray: Record<string, any>[] =
    form.getFieldValue(newAttrValGroupKey)
  const updatedArrtibuteValueGroupsArray: Record<string, any>[] =
    (isInNewAttrValGroup && [
      ...valGroupArray.map(g => (g ? g : {})), // empty object to keep empty groups before updated group (undefined will remove the group altogether)
    ]) ||
    []

  if (isInNewAttrValGroup) {
    updatedArrtibuteValueGroupsArray[index] = {
      ...updatedArrtibuteValueGroupsArray[index],
      [attrId]: Array.isArray(
        updatedArrtibuteValueGroupsArray?.[index]?.[attrId]
      )
        ? [
            ...(updatedArrtibuteValueGroupsArray?.[index]?.[attrId].filter(
              (cv: any) => {
                // remove empty lines (can be undefined or an empty array if field is repeatable)
                if (!cv) return false
                if (cv instanceof dayjs) return true
                return (
                  Array.isArray(cv) &&
                  cv.length !== 0 &&
                  !cv.every(elem => elem === undefined || elem === null)
                )
              }
            ) || []),
            ...(selectedDate as RepeatableDateSuggestionOptions),
          ]
        : selectedDate,
    }
  }

  const attrIsRepeatable = attributeValue.attribute.config.repeatable || false

  const currentValue = attributeValue.id
    ? form.getFieldValue(attributeValue.id)
    : undefined

  const formKey = isInNewAttrValGroup ? newAttrValGroupKey : attributeValue.id
  const value = isInNewAttrValGroup
    ? updatedArrtibuteValueGroupsArray
    : attrIsRepeatable
      ? [
          ...currentValue.filter((cv: any) => {
            // remove empty lines (can be undefind or an empty array if field is repeatable)
            if (!cv) return false
            if (cv instanceof dayjs) return true
            return (
              Array.isArray(cv) &&
              cv.length !== 0 &&
              !cv.every(elem => elem === undefined || elem === null)
            )
          }),
          ...(selectedDate as RepeatableDateSuggestionOptions),
        ] // always add to existing values if repeatable
      : selectedDate

  return { formKey, value }
}

const DateTimeRangeContainer = styled.div({
  display: 'flex',
  gap: 8,
  width: '100%',
})

const DateListContainer = styled.ul({
  flex: 2,
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  margin: 0,
})

const DateListEntryContainer = styled.div({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',

  '.ant-form-item-control-input-content': {
    display: 'flex',
  },

  '.ant-row.ant-form-item': {
    flexGrow: 1,
  },

  '&:not(:last-of-type)': { marginBottom: '.5rem' },
  '.ant-picker': {
    width: '100%',
  },
  '.ant-form-item': {
    marginBottom: 0,
  },
})

const DateListEntryButtonContainer = styled.div({
  marginRight: '.625rem',
  alignSelf: 'flex-start',
  // marginTop: 4,
  '& > button:not(:last-of-type)': {
    marginRight: '.625rem',
  },
})

const AddDateContainer = styled.div({
  display: 'flex',
  gap: 8,
  justifyContent: 'flex-end',
  margin: '0.25rem 0',
  '.ant-btn:last-of-type': {
    // width: '100%',
    flexGrow: 1,
  },
})

const InputWithSuggestionsWrapper = styled.div({
  display: 'flex',
  gap: 8,
  width: '100%',
  '.ant-row.ant-form-item': {
    flexGrow: 1,
  },
})

const StyledDatePickerAutoConfirm = styled(
  DatePickerAutoConfirm as React.ComponentType<DatepickerAutoconfirmProps> &
    RefAttributes<any>
)({
  '&.is-focused': {
    border: 'none',
  },
})

const SuggestionIcon = styled.div({})

const FormInput = styled.div({
  flexGrow: 1,
})
