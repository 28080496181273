import React from 'react'
import { BulbOutlined } from '@ant-design/icons'
import { useEventContext } from '@organice/contexts/event/event-context'
import { Dayjs } from 'dayjs'
import { Popover, Button, Collapse, Tooltip } from 'antd'
import { TimeUtil } from '@organice/utils/date'
import { dateConfig } from '@organice/config'
import { AttributeTypes_Enum } from '@organice/graphql'
import { convertDBDatesToDayjs } from '@organice/utils/date'
import { DateType } from '../../../types/service'
import styled from '@emotion/styled'
import { useFormContext } from '@organice/contexts/form'
import { useTranslation } from 'react-i18next'
import { DateTimeRangeSuggestion } from './date-time-range-suggestion'
import { DateRangeSuggestion } from './date-range-suggestion'
import { isRangeType, sortByOrderProperty, getDateVariants } from './utils'
import type {
  TargetField,
  SourceField,
  DateSuggestionOptions,
  EventAttrGroupsDataWithOrder,
  EventAttrGroupsDataWithoutOrder,
  EventDateType,
} from './types'

const dayjs = TimeUtil.getDayjs()

interface EventDateSuggestionsProps {
  onSelect?: (selected: DateSuggestionOptions) => void
  dateType: DateType
  isRepeatable?: boolean
  fieldId?: any
  size?: 'small' | 'middle' | 'large'
  icon?: React.ReactNode
}

export const EventDateSuggestions: React.FC<EventDateSuggestionsProps> = ({
  onSelect,
  dateType: targetDateType,
  isRepeatable: targetIsRepeatable = false,
  fieldId,
  size,
  icon,
}) => {
  const { t } = useTranslation()
  const { event } = useEventContext()
  const { form } = useFormContext()
  const fieldValue = fieldId ? form.getFieldValue(fieldId) : undefined

  const targetField: TargetField = {
    value: fieldValue,
    config: {
      dateType: targetDateType,
      repeatable: targetIsRepeatable,
    },
  }

  const dateClickHandler = (date: DateSuggestionOptions) => {
    onSelect && onSelect(date)
  }

  const getDateSuggetionsUI = (source: SourceField, target: TargetField) => {
    if (!event) return null
    const { config: sourceConfig } = source
    const { config: targetConfig } = target
    const typesWithTime = [
      DateType.DATE_RANGE_TIME,
      DateType.DATE_TIME,
      DateType.DATE_TIME_RANGE,
    ]

    const dateVariants = getDateVariants(source.value, source.config)
    if (!dateVariants) return null

    switch (targetConfig.dateType) {
      case DateType.DATE:
      case DateType.DATE_TIME:
      case DateType.DATE_RANGE:
      case DateType.DATE_RANGE_TIME:
        if (isRangeType(sourceConfig.dateType)) {
          if (sourceConfig.repeatable) {
            return (
              <AttributeWrapper>
                <AttributeTitle>{source.name}</AttributeTitle>
                {source.value.map((v: any, i: number) => (
                  <div className="dateSuggestion" key={`${source.name}-${i}`}>
                    <DateRangeSuggestion
                      showTime={typesWithTime.includes(targetConfig.dateType)}
                      sourceField={{ ...source, value: v }}
                      dateVariants={getDateVariants(v, {
                        ...source.config,
                        repeatable: false,
                      })}
                      targetField={target}
                      onSelect={selected => {
                        dateClickHandler(selected)
                      }}
                    />
                  </div>
                ))}
              </AttributeWrapper>
            )
          }

          return (
            <AttributeWrapper>
              <AttributeTitle>{source.name}</AttributeTitle>
              <div className="dateSuggestion">
                <DateRangeSuggestion
                  showTime={typesWithTime.includes(targetConfig.dateType)}
                  sourceField={source}
                  dateVariants={dateVariants}
                  targetField={target}
                  onSelect={selected => {
                    dateClickHandler(selected)
                  }}
                />
              </div>
            </AttributeWrapper>
          )
        }

        if (sourceConfig.dateType === DateType.DATE_TIME_RANGE) {
          if (sourceConfig.repeatable) {
            return (
              <AttributeWrapper>
                <AttributeTitle>{source.name}</AttributeTitle>
                {source.value.map((v: any, i: number) => (
                  <div className="dateSuggestion" key={`${source.name}-${i}`}>
                    <DateTimeRangeSuggestion
                      sourceField={{ ...source, value: v }}
                      dateVariants={getDateVariants(v, {
                        ...source.config,
                        repeatable: false,
                      })}
                      targetField={target}
                      onSelect={selected => {
                        dateClickHandler(selected)
                      }}
                    />
                  </div>
                ))}
              </AttributeWrapper>
            )
          }
          return (
            <AttributeWrapper>
              <AttributeTitle>{source.name}</AttributeTitle>
              <div className="dateSuggestion">
                <DateTimeRangeSuggestion
                  sourceField={source}
                  dateVariants={dateVariants}
                  targetField={target}
                  onSelect={selected => {
                    dateClickHandler(selected)
                  }}
                />
              </div>
            </AttributeWrapper>
          )
        }

        console.warn(
          `EventDateSuggestions: Unknown sourceConfig.dateType ${sourceConfig.dateType}`
        )
        return null
        break

      case DateType.DATE_TIME_RANGE:
        // source is also DATE_TIME_RANGE
        if (sourceConfig.dateType === DateType.DATE_TIME_RANGE) {
          if (sourceConfig.repeatable) {
            return (
              <AttributeWrapper>
                <AttributeTitle>{source.name}</AttributeTitle>
                {source.value.map((v: any, i: number) => (
                  <div className="dateSuggestion" key={`${source.name}-${i}`}>
                    <DateTimeRangeSuggestion
                      sourceField={{ ...source, value: v }}
                      dateVariants={getDateVariants(v, {
                        ...source.config,
                        repeatable: false,
                      })}
                      targetField={target}
                      onSelect={selected => {
                        dateClickHandler(selected)
                      }}
                    />
                  </div>
                ))}
              </AttributeWrapper>
            )
          }
          return (
            <AttributeWrapper>
              <AttributeTitle>{source.name}</AttributeTitle>
              <div className="dateSuggestion">
                <DateTimeRangeSuggestion
                  sourceField={source}
                  dateVariants={dateVariants}
                  targetField={target}
                  onSelect={selected => {
                    dateClickHandler(selected)
                  }}
                />
              </div>
            </AttributeWrapper>
          )
        }

        // source is Range
        if (isRangeType(sourceConfig.dateType)) {
          if (sourceConfig.repeatable) {
            return (
              <>
                <AttributeTitle>{source.name}</AttributeTitle>
                {source.value.map((v: any, i: number) => (
                  <div className="dateSuggestion" key={`${source.name}-${i}`}>
                    <DateRangeSuggestion
                      showTime={typesWithTime.includes(targetConfig.dateType)}
                      sourceField={{ ...source, value: v }}
                      dateVariants={getDateVariants(v, {
                        ...source.config,
                        repeatable: false,
                      })}
                      targetField={target}
                      onSelect={selected => {
                        dateClickHandler(selected)
                      }}
                    />
                  </div>
                ))}
              </>
            )
          }

          return (
            <AttributeWrapper>
              <AttributeTitle>{source.name}</AttributeTitle>
              <div className="dateSuggestion">
                <DateRangeSuggestion
                  showTime={typesWithTime.includes(targetConfig.dateType)}
                  sourceField={source}
                  dateVariants={dateVariants}
                  targetField={target}
                  onSelect={selected => {
                    dateClickHandler(selected)
                  }}
                />
              </div>
            </AttributeWrapper>
          )
        }
        console.warn(
          `EventDateSuggestions: Unclear what to return for ${targetConfig.dateType}`
        )
        return null
    }
  }

  const popverContent = React.useMemo(() => {
    const eventAttributeValues = event?.eventAttributeValues

    const eventAttributeGroupsObj: Record<
      string,
      EventAttrGroupsDataWithOrder
    > = {}

    eventAttributeValues?.forEach(evAttrVal => {
      if (
        evAttrVal.eventAttribute?.attributeType === AttributeTypes_Enum.Date
      ) {
        const attrGroup = evAttrVal.eventAttribute.eventAttributeGroup
        const attr = evAttrVal.eventAttribute
        const value = evAttrVal.value.value

        if (!(attrGroup.id in eventAttributeGroupsObj)) {
          eventAttributeGroupsObj[attrGroup.id] = {
            ...attrGroup,
            attributes: [],
          }
        }

        const isRepeatable = attr.config?.repeatable || false

        eventAttributeGroupsObj[attrGroup.id].attributes.push({
          ...attr,
          value:
            isRepeatable && Array.isArray(value)
              ? value.map(v =>
                  convertDBDatesToDayjs({
                    value: v,
                    type: attr.config?.dateType as DateType,
                  })
                )
              : convertDBDatesToDayjs({
                  value: value,
                  type: attr.config?.dateType as DateType,
                }),
        })

        eventAttributeGroupsObj[attrGroup.id].attributes.sort(
          sortByOrderProperty
        )
      }
    })

    const eventRange: EventDateType = {
      key: 'eventStart',
      name: t('events.eventDatePeriod'),
      value: [dayjs(event?.startDate), dayjs(event?.endDate)],
      format: dateConfig.DATE_FORMAT,
      config: { dateType: DateType.DATE_RANGE, repeatable: false },
    }

    const eventAttributesDate: EventAttrGroupsDataWithOrder[] = Object.keys(
      eventAttributeGroupsObj
    )
      .map(attrGroupId => eventAttributeGroupsObj[attrGroupId])
      .sort(sortByOrderProperty)

    const sourceData: (
      | EventAttrGroupsDataWithoutOrder
      | EventAttrGroupsDataWithOrder
    )[] = [
      {
        name: t('events.eventDatePeriod'),
        attributes: [eventRange],
      },
      ...eventAttributesDate,
    ]

    return (
      <Collapse defaultActiveKey={[0]}>
        {sourceData.map((dateGroup, i) => (
          <Collapse.Panel header={dateGroup.name} key={i}>
            {dateGroup.attributes.map((dateAttr, j) => (
              <React.Fragment key={`${i}-${j}`}>
                {getDateSuggetionsUI(dateAttr, targetField)}
              </React.Fragment>
            ))}
          </Collapse.Panel>
        ))}
      </Collapse>
    )
  }, [event])

  if (!event) return null

  return (
    <Popover content={popverContent} trigger="click" placement="left">
      <Tooltip
        placement="topLeft"
        title={t('events.actions.tooltips.showDateSuggestions')}
      >
        <Button icon={icon || <BulbOutlined />} size={size} />
      </Tooltip>
    </Popover>
  )
}

const AttributeWrapper = styled.div`
  margin-bottom: 1rem;

  .anticon {
    margin-right: -5px;
  }

  .dateSuggestion {
    border: 1px solid ${props => props.theme.lightGreyBackground};
  }

  .dateSuggestion:nth-of-type(odd) {
    background: ${props => props.theme.lightGreyBackground};
  }
`
const AttributeTitle = styled.span`
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
`
